/* stylelint-disable selector-list-comma-newline-after */

.logo {
  max-width: 240px;
}

#main-container {
  max-width: 1268px; // against ultra wides
  margin: 0 auto;
}

main { min-height: 63vh; }


h1, h2, h3, h4, h5, h6 {
  font-family: "DM Serif Display", Georgia, "Times New Roman", serif;
}

.display-4 {
  font-size: 2.5rem;
}

@media (min-width: 768px) {
  .display-4 {
    font-size: 3rem;
  }
}



.nav {
  &-links a {
    text-decoration: none;

    &.active {
      border-bottom: 2px solid;
    }
  }
}



/** Card Content **/
.card-container:first-of-type {
  display:none;
}
.card-download {
  text-decoration: none;
}

/** utils **/
.z-n1 { z-index: -1; }
.row { max-width: 100vw; }
.text-right { text-align: right; }
.flex-auto { flex: 0 0 auto; }
.border {
  &-top { border-top: 1px solid #e5e5e5; }
  &-bottom { border-bottom: 1px solid #e5e5e5; }
}
.box-shadow { box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05); }

/*
 * Footer
 */
.page-footer {
  padding: 2.5rem 0;

  text-align: center;
  border-top: .05rem solid #e5e5e5;

  
  &-footer p:last-child {
    margin-bottom: 0;
  }
}


@mixin hero-figure {
  background:url('../public/background.jpg');
  background-size: cover;
  background-attachment: bottom;
  background-position: center;
}


#hero {
  height: max-content;
  .hero-title {
    .jumbotron {
      height: 100%;
    }
  }
  .hero-figure {
    min-height: 330px;
    @include hero-figure;
  }
}

@mixin hero-small {
  #hero {
    .hero-title {
      @include hero-figure;
      
      .jumbotron {
        background-color:rgba(255,255,255,0.5)
      }
    }
    .hero-figure { display:none; }
  }
}

/** Themes **/

.theme- {
  &default {
    h1, h2, h3, h4, h5, h6, .display-4 { 
      color: #212529;
    }
    p, a, .text-muted {
      color: #343a40 !important;
    }

    .card-download {
      color: #495057 !important;
    }
    .page-footer {
      color: #999;
      background-color: #f9f9f9;
      border-top-color: #e5e5e5;
    }
  }

  &earth {
    $color1: #28312f;
    $color3: #de7130;
    $color4: #f5f4f0;

    h1, h2, h3, h4, h5, h6, .display-4 { 
      color: $color1;
    }

    p, a, .text-muted {
      color: lighten($color1, 10%) !important;
    }

    a.btn-outline-secondary {
      border-color: $color1 !important;
    }

    .card-download {
      color: $color3 !important;
      font-weight: 600;
    }
    .page-footer {
      p {
        color: $color3 !important;
      }
      background-color: $color4;
      border-top-color: darken($color4, 25%);
    }
  }

  &purple {
    $color1: #533fa0;
    $color3: #777777;
    $color4: #111820;

    h1, h2, h3, h4, h5, h6, .display-4 { 
      color: $color1;
    }
    .display-4, h3, h5 {
      font-family: 'Vollkorn', Georgia, 'Times New Roman', Times, serif;      
    }

    p, a, .text-muted {
      color: lighten($color1, 10%) !important;
    }

    a.btn-outline-secondary {
      border-color: $color1 !important;
    }

    .card-download {
      color: $color3 !important;
      font-weight: 600;
    }
    .page-footer {
      p {
        color: $color3 !important;
      }
      background-color: $color4;
      border-top-color: lighten($color4, 25%);
    }
  }
}




/** Media Queries **/

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  @include hero-small;
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  @include hero-small;
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) { }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { }

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) { }